.dashboardContainer {
    /* margin-top: 100px; */
    width: 100%;
    min-height: 100vh;
  }

  .home-row {
    flex-wrap: nowrap !important;

  }
  
  .button-container {
    display: flex;
    width: 75vw;
    justify-content: flex-end;
    color: #B22335;
  }

  #page-container {
    display: flex !important;
    padding: 0;
    margin: 0;
    padding-left: 25px;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

#help-me {
  margin: 0 !important;
  height: 100%;
}

.nav-col {
  border-right-style: ridge;

}

.page-col {
  padding: 10px;
  margin: 10px;
}

.edit-title {
    text-align: center;
    padding: 30px;
    color:#004B80;
}

.edit-resInfo {
    padding: 20px;
    color: #C20131;
}

.edit-text-field {
    margin-top: 5px;
    width: 275px !important;
    padding-left: 0;

}

.edit-my-modal {
    min-width: 650px;
}

.edit-input-container {
    display: flex;
    justify-content: space-evenly;
    padding: 0;
}

.edit-bottom-input-container {
    display: flex;
    justify-content: flex-start;
}

.edit-description {
    min-width: 100% !important;
    padding: 20px !important;
    margin-top: 20px !important ;
}

.edit-button-container {
    display: flex;
    justify-content: flex-end;
}

.edit-my-cancel {
    max-width: 95px !important;
    height: 45px !important;
    background-color: white;
    border: none;
    color: #C20131;
}

.edit-my-save {
    max-width: 95px !important;
    border-radius: 10px !important;
    height: 45px !important;
    background-color: #004B80 !important;
    color: white !important;

}
.imagesize {
    max-width: auto;
    max-height: 75px;
  }
  li:hover {
    cursor: pointer;
  }
  
  th {
    color: #C20131;
    font: 14px;
    border-top: none;
  }
  
  th:hover {
    cursor: pointer;
    text-align: left;
  }
  
  td {
    font-size: 11px;
    background-color: #C20131;
    text-align: left;
  }
  
  h1 {
    text-align: left;
    font-size: 40px;
    letter-spacing: -0.92px;
    color: #0D5486;
    opacity: 1;
  }

.table-container {
    width: 100%;
    padding: 15px;
}

.add-resource {
    display: flex;
    justify-content: flex-end;
    padding: 10px;
}

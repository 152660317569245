.my-save {
    max-width: 95px !important;
    border-radius: 10px !important;
    height: 45px !important;
    background-color: #004B80 !important;
    color: white !important;

}

.reset-button {
    max-width: 200px !important;
    border-radius: 10px !important;
    height: 45px !important;
    background-color: #004B80 !important;
    color: white !important;
}

.my-cancel {
    max-width: 95px !important;
    height: 45px !important;
    background-color: white;
    border: none;
    color: #C20131;
}

.resInfo {
    padding: 20px;
    color: #C20131;
}